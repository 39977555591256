<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
    <div class="app-header__logo" style="background: #fff;">
      <a *ngIf="getItem() != 'consultant'" routerLink="/csv-parser" routerLinkActive="active-item"><img class="logoimage" /> <span class="webtitle">Big Database</span></a>
    </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="dashboardsMenu" *ngIf="isLoggedInUser && getItem() == 'admin'" >
                <ng-template ngbPanelHeader>
                  <button routerLink="/csv-parser" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-plane"></i>
                    <span class="vsm-title">Csv Parser</span>
                  </button>
                  <button routerLink="/data-filter" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Data Filter</span>
                  </button>
                  <button routerLink="/data-filter-custom" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Data Filter Custom</span>
                  </button>
                  <button routerLink="/logs" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Logs</span>
                  </button>
                  <button routerLink="/sftp-lyson" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">SFTP Lyson</span>
                  </button>
                  <button (click)="Logout($event)" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-power"></i>
                    <span class="vsm-title">Logout</span>
                  </button>
                </ng-template>
              </ngb-panel>
              
              <!-- <ngb-panel id="AdministrationMenu" *ngIf="isLoggedInUser && getItem() == 'admin'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Administration</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/areas" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Thematic Areas</a>
                    </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>

            <div class="vsm-item" *ngIf="!isLoggedInUser">
              <a routerLink="/sign-in" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph2"></i>
                <span class="vsm-title">Login</span>
              </a>
            </div>
            
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
